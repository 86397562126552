import React, { useRef } from "react";
import { Container, Row, Col } from "react-bootstrap";
import missionIcon from "../../../../Assets/Group 818.png";
import valuesIcon from "../../../../Assets/valuesIcon.png";
import visionIcon from "../../../../Assets/visionIcon.png";
import Group from "../../../../Assets/Group 1413.png";
//import { mediaBaseUrl } from "../../../../services";
import "./AboutUs.css";
function AboutUs({ active }) {
	const about = useRef(null);

	const modals = [
		{
			description:
				"We, at Maqraa, aim to support the Egyptian community and families by making their necessities more affordable to them at the touch of a button.",
			image: missionIcon,
			name: "Our Mission",
		},
		{
			description:
				"Maqraa offers solutions for financial struggles or those looking to save. Futuristically, we aim to increase money circle amounts, create product circles and expand the concept of Maqraa to the MENA region.",
			image: visionIcon,
			name: "Our Vision",
		},
		{
			description:
				"We are keen on making necessities attainable to our users by offering them solutions through banking, equality, and security.",
			image: valuesIcon,
			name: "Our Values",
		},
	];

	const executeScroll = (ref) => ref.current.scrollIntoView();

	if (active === "about") {
		executeScroll(about);
	}

	return (
		<div ref={about} className="aboutUs">
			<div className=" pt-5">
				<Container>
					<Row>
						<Col sm>
							<div className="textLanding py-5 mt-2">
								<h3 className="mb-0 aboutUsHeading">About us</h3>
								<div className="borderLine"></div>
								<article>
									<p className="s3 mb-4">
										Maqraa is a present-day “gam3eya” designed to organize and
										facilitate financial struggles in the Egyptian community.
										Maqraa App users benefit by collecting money through joining
										money circles in the form of 3, 6, 12, and 18-month
										installment plans and choosing their slots in the circles.
										What makes Maqraa better than the ordinary “gam3eya” is
										ensures equal benefits for all users by having different
										benefits for each slot of the money circles for the users.
										Maqraa is 100% secure, guaranteed and an easy process for
										all users.
									</p>
								</article>
							</div>
						</Col>
						<Col sm>
							{/* <img src={landingImg} className="landingImg" alt="landingImg" /> */}
							<div className="group">
								<img src={Group} alt="Group" className="groupImg" />
							</div>
						</Col>
					</Row>
				</Container>
			</div>
			<div className="mission">
				<Container fluid>
					{modals.map((model, index) => {
						return (
							<div className="modaal py-2" key={index}>
								{index % 2 === 0 ? (
									<Row key={index} className="roseBox">
										<Col md={6}>
											<img
												src={model.image}
												alt="missionImg"
												className="missionImg"
											/>
										</Col>
										<Col md={6}>
											<div className="textMission">
												<h3>{model.name}</h3>
												<p className="s2">{model.description}</p>
											</div>
										</Col>
									</Row>
								) : (
									<Row key={index} className="roseBox white">
										<Col sm>
											<div className="textMission">
												<h3>{model.name}</h3>
												<p className="s2">{model.description}</p>
											</div>
										</Col>

										<Col sm>
											<img
												src={model.image}
												alt="missionImg"
												className="missionImg"
											/>
										</Col>
									</Row>
								)}
							</div>
						);
					})}
				</Container>
			</div>
		</div>
	);
}

export default AboutUs;
