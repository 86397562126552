import React, { useRef } from "react";
import landingImg from "../../../../Assets/Icons/logo.png";
import { Container, Row, Col, Nav } from "react-bootstrap";
import "./LandingPage.css";
function LandingPage({ active, getActive }) {
	const home = useRef(null);

	const executeScroll = (ref) => ref.current.scrollIntoView();

	if (active === "home") {
		executeScroll(home);
	}

	return (
		<div className="landingPage" ref={home}>
			<Container>
				<Row>
					<Col sm>
						<div className="textLanding">
							<h1 className="s1">Learn to recite the Holy Quran</h1>
							<p className="s3">
								An electronic recitation of the Holy Quran , carried out by a
								group of specialized sheikhs to teach the recitation of the Holy
								Quran to Muslims everywhere
							</p>

							<Nav.Link
								href={
									localStorage.getItem("deviceType") === "ANDROID"
										? "https://play.google.com/store/apps/details?id=com.maqraa"
										: "https://apps.apple.com/eg/app/%D8%A7%D9%84%D9%85%D9%82%D8%B1%D8%A3%D9%87/id6443391030"
								}
								className="btn">
								Download
							</Nav.Link>
						</div>
					</Col>
					<Col sm>
						<div className="imgContainer">
							<img src={landingImg} alt="landingImg" className="landingImg" />
						</div>
					</Col>
				</Row>
			</Container>
		</div>
	);
}

export default LandingPage;
