import React from "react";
import { Switch, Route } from "react-router-dom";
import Home from "./modules/Home/Home";
import Score from "./modules/Score/Score";
function Routes(props) {
  return (
    <Route
      render={({ location }) => (
        <Switch location={location}>
          <Route path="/" render={() => <Home />} />
        </Switch>
      )}
    />
  );
}

export default Routes;
