import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import AndroidImg from "../../../Assets/Icons/Google.svg";
import IosImg from "../../../Assets/Icons/App.svg";
import InstgramImg from "../../../Assets/Icons/Instgram.svg";
import FaceBookImg from "../../../Assets/Icons/Facebook.svg";
import mobile from "../../../Assets/Icons/Mobile.svg";
import msg from "../../../Assets/Icons/Msg.svg";
import "./Footer.css";
function Footer({ getActive }) {
	return (
		<div className="footer">
			<Container>
				<Row>
					<Col sm>
						<div className="Downloads">
							<h5>Downloads</h5>
							<div>
								<a
									href={
										localStorage.getItem("deviceType") === "ANDROID"
											? "https://play.google.com/store/apps/details?id=com.maqraa"
											: "https://apps.apple.com/eg/app/%D8%A7%D9%84%D9%85%D9%82%D8%B1%D8%A3%D9%87/id6443391030"
									}
									target="_blank"
									rel="noreferrer">
									<img
										src={AndroidImg}
										alt="AndroidImg"
										className="AndroidImg"
									/>
								</a>
							</div>
							<div>
								<a
									href={
										localStorage.getItem("deviceType") === "ANDROID"
											? "https://play.google.com/store/apps/details?id=com.maqraa"
											: "https://apps.apple.com/eg/app/%D8%A7%D9%84%D9%85%D9%82%D8%B1%D8%A3%D9%87/id6443391030"
									}
									target="_blank"
									rel="noreferrer">
									<img src={IosImg} alt="IosImg" className="IosImg" />
								</a>
							</div>
						</div>
					</Col>
					{/* <Col sm>
						<div className="Packages">
							<h5>Packages</h5>
							<a href="#Portfolio" onClick={() => getActive("Plans")}>
								Silver package
							</a>
							<a href="#Portfolio" onClick={() => getActive("Plans")}>
								Gold Package
							</a>
							<a href="#Portfolio" onClick={() => getActive("Plans")}>
								Platinum Package{" "}
							</a>
						</div>
					</Col> */}
					{/* <Col sm>
						<div className="SiteMap">
							<h5>Site Map</h5>
							<a
								onClick={() => {
									getActive("home");
								}}
								href="#home">
								Home
							</a>
							<a href="#about" onClick={() => getActive("about")}>
								About us
							</a>
							<a href="#Portfolio" onClick={() => getActive("Plans")}>
								Download
							</a>

							<a href="#Contact" onClick={() => getActive("ContactUs")}>
								Contact us
							</a>
						</div>
					</Col> */}
					<Col sm>
						<div className="SocialMedia">
							<h5>Social Media</h5>
							<div className="social">
								<span>
									<a
										href="https://instagram.com/almaqraa.app?igshid=MzRlODBiNWFlZA=="
										target="_blank"
										rel="noreferrer">
										<img
											src={InstgramImg}
											alt="InstgramImg"
											className="InstgramImg"
										/>
									</a>
								</span>
								<span>
									<a
										href="https://www.facebook.com/profile.php?id=100076429800336&mibextid=2JQ9oc"
										target="_blank"
										rel="noreferrer">
										<img src={FaceBookImg} alt="FaceBookImg" />
									</a>
								</span>
								<div className="info">
									<img src={mobile} alt="mobile" className="m-2" />
									01100820963
								</div>
								{/* <div className="info">
									<img src={msg} alt="msg" className="m-2 " />
									info@dayraa.com
								</div> */}
							</div>
						</div>
					</Col>
				</Row>
			</Container>
			<div>
				<p className="copyRightBar">©2023 Maqraa . All Rights Reserved.</p>
			</div>
		</div>
	);
}

export default Footer;
