import React, { useRef, useState } from "react";
import { Container, Row, Col, Alert } from "react-bootstrap";
import "./ContactUs.css";

const api_key = "402066dcc8154d4e6230b09002c29779-ed4dc7c4-be6cbb61";
const domain = "kadabra.app";
const mailgun = require("mailgun-js")({ apiKey: api_key, domain: domain });

function ContactUs({ active }) {
	const ContactUs = useRef(null);

	const executeScroll = (ref) => ref.current.scrollIntoView();

	if (active === "ContactUs") {
		executeScroll(ContactUs);
	}

	const [firstName, setFirstName] = useState("");
	const [lastName, setLastName] = useState("");
	const [email, setEmail] = useState("");
	const [message, setMessage] = useState("");
	const [response, setResponse] = useState(null);
	const [errAlert, setErrAlert] = useState(false);
	const [sentFlag, setSentFlag] = useState(false);

	const handleSubmit = (e) => {
		e.preventDefault();
		if (
			firstName.length === 0 ||
			lastName.length === 0 ||
			email.length === 0 ||
			message.length === 0
		) {
			setErrAlert(true);
		} else {
			var data = {
				from: "NationTech <zaid.sameh@Maqraa.app>",
				to: "zaid.sameh@Maqraa.app",
				subject: "Maqraa",
				text: `First Name : ${firstName} Last Name: ${lastName} - Email:${email} - message:${message}`,
			};

			mailgun.messages().send(data, function (error, body) {
				setEmail("");
				setFirstName("");
				setLastName("");
				setMessage("");
				setErrAlert(false);
				setSentFlag(true);
			});
		}
	};

	if (response) {
		setTimeout(() => {
			setResponse(null);
		}, 4000);
	}

	return (
		<div className="contactUs" ref={ContactUs}>
			<Container>
				<h3 className="mb-0 aboutUsHeading">Contact us</h3>
				<div className="borderLine"></div>
				<p className="s3 ">Have an inquiry or some feedback for us ?</p>
				<p className="s3 ">Fill Out the form below to contact our team.</p>
				<div className="formContainer">
					{response && (
						<div
							className={
								response.status === 200
									? "messageBox successMessage s3 my-2"
									: "messageBox failMessage s3 my-2"
							}>
							{response.message}
						</div>
					)}
					<form onSubmit={handleSubmit}>
						<Row>
							<Col md={6}>
								<div className="form-control-box">
									<label>First Name</label>
									<div>
										<input
											type="text"
											className={
												errAlert && firstName === ""
													? "form-control alert"
													: "form-control "
											}
											onChange={(e) => setFirstName(e.target.value)}
											value={firstName}
										/>
									</div>
								</div>
							</Col>
							<Col md={6}>
								<div className="form-control-box">
									<label>Last Name</label>
									<div>
										<input
											type="text"
											className={
												errAlert && lastName === ""
													? "form-control alert"
													: "form-control "
											}
											onChange={(e) => setLastName(e.target.value)}
											value={lastName}
										/>
									</div>
								</div>
							</Col>
							<div className="form-control-box">
								<label>Email Address</label>
								<input
									type="email"
									className={
										errAlert && email === ""
											? "form-control alert"
											: "form-control "
									}
									onChange={(e) => setEmail(e.target.value)}
									value={email}
								/>
							</div>
							<div className="form-control-box">
								<label>Message</label>
								<textarea
									className={
										errAlert && message === ""
											? "form-control alert"
											: "form-control "
									}
									rows="5"
									col="12"
									name="message"
									onChange={(e) => setMessage(e.target.value)}
									value={message}
								/>
							</div>
							<div className="btnContainer">
								<button className="btn">Send</button>
							</div>
							{sentFlag && (
								<Alert className="text-center">Your message was sent !</Alert>
							)}
						</Row>
					</form>
				</div>
			</Container>
		</div>
	);
}

export default ContactUs;
